<template>
  <vue-final-modal
    v-bind="$attrs"
    :ssr="false"
    :class="{ _blurred: isBlurred }"
    :name="nameModal"
    :click-to-close="!isUnclosed"
    :z-index="100503"
    attach="body"
    classes="modal-container"
    content-class="modal-container__content-holder"
    v-on="$listeners"
    @before-open="handlerBeforeOpen"
    @before-close="handlerBeforeClose"
    @opened="handlerOpened"
    @closed="handlerClosed"
  >
    <div
      v-if="!isUnclosed"
      class="modal-container__header"
      :class="{
        '_transparent': isTransparentHeader,
        '_absolute': isAbsoluteHeader,
      }"
    >
      <button
        aria-label="Закрыть"
        class="modal-container__close"
        type="button"
        @click="handleClickClose"
      >
        <svg-icon
          name="24/Close_24"
          :width="24"
          :height="24"
          class="modal-container__close-icon"
        />
      </button>
    </div>
    <div
      :class="{ _unclosed: isUnclosed }"
      class="modal-container__content"
    >
      <slot></slot>
    </div>
    <loader-block v-if="isLoading" text="" class="modal-container__loader" />
  </vue-final-modal>
</template>

<script>
import { LoaderBlock } from '@book24/ui-components';
import useModalHandlers from '@/compositions/modal/useModalHandlers';

export default {
  name: 'AppModalDrawer',
  components: {
    LoaderBlock,
  },
  inheritAttrs: false,
  props: {
    isTransparentHeader: {
      type: Boolean,
      default: false,
    },
    isAbsoluteHeader: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isUnclosed: {
      type: Boolean,
      default: false,
    },
    isBlurred: {
      type: Boolean,
      default: false,
    },
    nameModal: {
      type: String,
      default: 'AppModalDrawer',
    },
  },
  setup() {
    const {
      handlerBeforeOpen,
      handlerBeforeClose,
      handlerClosed,
      handlerOpened,
    } = useModalHandlers();

    return {
      handlerBeforeOpen,
      handlerBeforeClose,
      handlerClosed,
      handlerOpened,
    };
  },
  methods: {
    handleClickClose() {
      this.$vfm.hide(this.nameModal);
    },
  },
};
</script>

<style scoped lang="less">
::v-deep.vfm--absolute {
  position: fixed;

  &._blurred {
    backdrop-filter: blur(6px);
  }

  &._android-app-modal .modal-container {
    overflow-y: unset;
  }
}

::v-deep .modal-container {
  top: auto;
  max-height: 95%;
  overflow-y: auto;

  &__content-holder {
    background: @white;
    width: 100%;
    height: 100%;
  }

  &__content {
    width: 100%;
    height: 100%;
    max-width: @max-width-m;
    padding: 0 @size-x4 @size-x4;
    margin: 0 auto;

    &._unclosed {
      padding: @size-x5 @size-x4;
    }
  }

  &__header {
    background-color: @white;
    padding: @size-x2 (@main-size * 11) @size-x2 @size-x8;
    position: sticky;
    top: 0;
    left: 0;
    min-height: @main-size * 11;
    z-index: 4;

    &._transparent {
      background-color: transparent;
    }

    &._absolute {
      position: absolute;
      right: 0;
    }
  }

  &__close {
    .button-reset;

    position: absolute;
    top: 0;
    right: 0;
    color: @gray;
    transition: @A color;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    &._tap {
      color: @gray-dark;
    }
  }

  &__close-icon {
    fill: currentColor;
  }

  &__loader {
    z-index: 999;
    opacity: 0.8;
  }
}
</style>

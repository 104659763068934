import { getCurrentInstance } from '@nuxtjs/composition-api';

const useModalHandlers = (props) => {
  const { emit } = getCurrentInstance();

  const setOpenMenu = () => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.open_menu = true;
  };

  const removeOpenMenu = () => {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.open_menu = false;
  };

  const unlockScroll = () => {
    document.body.style.width = '';
  };

  const setBlockScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const setUnlockScroll = () => {
    unlockScroll();
    document.body.style.overflow = '';
  };

  const handlerBeforeOpen = (event) => {
    emit('before-open', event);
    // запрещаем рекламе показываться
    setOpenMenu();
  };

  const handlerBeforeClose = (event) => {
    emit('before-close', event);
    emit('close-search');
    unlockScroll();
    // разрешаем рекламе показываться
    removeOpenMenu();
  };

  const handlerOpened = (event) => {
    emit('opened', event);
  };

  const handlerClosed = (event) => {
    emit('closed', event);
  };

  const handlerClickClose = () => {
    emit('input', false);
  };

  // Костыль для предотвращения закрытия модалки по клику на скролл-бар
  const handlerClickOutside = () => {
    if (props.isUnclosed) return;
    const overlay = document.querySelector('.vfm__container');
    overlay.addEventListener('click', ({ target }) => {
      if (target === overlay) handlerClickClose();
    });
  };

  return {
    handlerBeforeOpen,
    handlerBeforeClose,
    handlerOpened,
    handlerClosed,
    handlerClickClose,
    handlerClickOutside,
    unlockScroll,
    setBlockScroll,
    setUnlockScroll,
  };
};

export default useModalHandlers;
